class MouseButton {
    constructor(el, wrapper) {
        this.el = el;
        this.wrapper = wrapper;
        console.log("Mouse button created");
        this.el.addEventListener("click", this.onClick.bind(this));
    }
    static create(el, wrapper) {
        return new this(el, wrapper);
    }
    onClick(e) {
        console.log("Mouse down");
        const scrollTarget = this.wrapper.nextElementSibling;
        if (scrollTarget) {
            scrollTarget.scrollIntoView({ behavior: "smooth" });
        }
    }
}
export default MouseButton;
